export const environment = {
  production: true,
  API_URL: "https://server.gigmor.com",
  APP_URL: "https://pwa.gigmor.com",
  version: '1.92',
  build: '19207',
  mode: "prod",
  cdn: "https://gigmor.azureedge.net",
  mixpanel: "e7fbe2b2c2f5e7df37f63dd491ad7e65",
  firebaseConfig: {
    apiKey: "AIzaSyDM6VoGjL8dcVn06YLZWARS2wu0IZ6zCwI",
    authDomain: "gigmor-85528.firebaseapp.com",
    databaseURL: "https://gigmor-85528-default-rtdb.firebaseio.com",
    projectId: "gigmor-85528",
    storageBucket: "gigmor-85528.appspot.com",
    messagingSenderId: "222352279584",
    appId: "1:222352279584:web:05bf6a33f88981051c9445",
    measurementId: "G-YZQRLZBGVR",
    domainUriPrefix: "https://link.ggmo.co",
  },
  glassfyKey: "58c9f61788a64e9b8b09383c20e32996",
  stripeKey: "pk_live_4aKtKjDEhlxkCdW7i4hiEV3p",
  stripeKeyLive: "pk_live_4aKtKjDEhlxkCdW7i4hiEV3p",
  logRocketAppId: "sskxkh/gigmor",
  firbaseDynamicLinkUrl: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDM6VoGjL8dcVn06YLZWARS2wu0IZ6zCwI`,
  revenueCatIosKey:'appl_OwkcdQLcaNZUnjAuxEPhWvrtbIi',
	revenueCatAndroidKey: 'goog_EmohyxYcnFQlKolkWfjbUyuLQWy'
};
